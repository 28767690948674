import {
  flattenDeep, get, last, split, startCase, isString, isEmpty, isArray, isObject, has, set, groupBy,
  keys,
  cloneDeep,
  isNumber,
  sortBy,
} from 'lodash';
import * as Sentry from '@sentry/react';
import translateCodeAndMessageToFlag from './RightPanel/ReviewCard/utils';
import {
  getUrlMetadata, isAuditPortalURL, isNonEmptyArray, isValidURL,
} from '../../utils/helpers';
import errorCode from '../../constants/errorCode';
import { formatDateForTimelineHeader, formatDateForTimelineView } from '../../utils/dateFormats';
import { URLS } from '../../constants/timelineView';

export const summaryModuleId = 'summary__container';
export const reasonBehindDivModuleId = 'reason_behind_status_container';

const sortAuditData = (auditArray) => sortBy(auditArray, 'createdAt');

const getModuleIdNameAndSubtype = ({
  recordModuleId,
  originalUrl,
  workflowModules,
  cardType,
  endpointModule,
}) => {
  const moduleId = recordModuleId
  || originalUrl
  || `unknown_api_${(Math.random() + 1).toString(36).substring(2)}`;

  const moduleName = get(workflowModules, [recordModuleId, 'name'])
      || get(endpointModule, 'moduleName')
      || startCase(last(split(originalUrl, '/')))
      || 'Unknown API';

  const moduleSubType = get(workflowModules, [recordModuleId, 'subType'])
  || cardType
  || 'default';

  return { moduleId, moduleSubType, moduleName };
};

function findNestedObj(entireObj, pathToFind) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (isObject(nestedValue) && has(nestedValue, pathToFind)) {
      foundObj = get(nestedValue, pathToFind);
    }
    return nestedValue;
  });
  return foundObj;
}

export const findStatus = ({ extractedData, statusCode }) => {
  let status;
  if (extractedData && isObject(extractedData)) {
    if (isArray(extractedData) && !isEmpty(extractedData)) {
      status = get(flattenDeep(extractedData).find(({ key }) => key === 'action'), 'value');
    } else {
      status = findNestedObj(extractedData, 'summary.action');
    }
  }
  if (!status) {
    if (statusCode === 422 || (statusCode >= 433 && statusCode <= 450)) {
      status = 'manualReview';
    } else if (statusCode >= 200 && statusCode < 300) {
      status = ('pass');
    } else status = 'unExpectedError';
  }
  return status;
};

const findIsSuccess = ({ extractedData, statusCode }) => {
  const summaryStatus = get(flattenDeep(extractedData).find(({ key }) => key === 'action'), 'value');
  if ((isString(summaryStatus) && summaryStatus.toLowerCase() === 'pass') || (!summaryStatus && (statusCode >= 200 && statusCode < 300))) {
    return true;
  }
  return false;
};

const mapStatementImageURLS = (
  statements,
  videoStatementModuleAPIRecords,
) => {
  try {
    const statementWithFileURLs = statements.map((videoStatement) => {
      const statement = cloneDeep(videoStatement);
      const { faceMatch, liveness } = statement;
      const faceMatchReqId = faceMatch?.requestId || faceMatch?.apiResponse?.metadata?.requestId || '';
      const livenessReqId = liveness?.requestId || liveness?.apiResponse?.metadata?.requestId || '';
      const faceMatchResponseData = videoStatementModuleAPIRecords[faceMatchReqId] || [];
      const livenessResponseData = videoStatementModuleAPIRecords[livenessReqId] || [];
      const faceMatchURLS = faceMatchResponseData && faceMatchResponseData.length
        ? faceMatchResponseData[0].fileUrls : [];
      const livenessURLS = livenessResponseData && livenessResponseData.length
        ? livenessResponseData[0].fileUrls : [];
      statement.faceMatch = { ...statement.faceMatch, fileUrls: faceMatchURLS };
      statement.liveness = { ...statement.liveness, fileUrls: livenessURLS };
      return statement;
    });
    return statementWithFileURLs;
  } catch (error) {
    Sentry.captureException(`${errorCode.UNHANDLED_ERROR} - ${error}`);
    return [];
  }
};

const processRecordsForSKYC = (groupedRecordData) => {
  if (!groupedRecordData) return groupedRecordData;

  try {
    const recordsData = cloneDeep(groupedRecordData) || {};
    const videoStatementRecords = groupedRecordData['/v1/logVideoStatement']?.records || [];
    let convertAPIRecords = groupedRecordData['/v1/convert']?.records || [];
    convertAPIRecords = groupBy(convertAPIRecords, 'journeyId');
    if (videoStatementRecords && videoStatementRecords.length > 0) {
      const finalVideoStatements = videoStatementRecords.map((videoStatementRecord) => {
        const record = cloneDeep(videoStatementRecord);
        const { moduleId } = record;
        let videoStatementModuleAPIRecords = groupedRecordData[moduleId]?.records || [];
        videoStatementModuleAPIRecords = groupBy(videoStatementModuleAPIRecords, 'requestId');
        const { journeyId } = record;
        const statements = get(record, 'extractedData.details.statements', []);
        const videoUrl = (convertAPIRecords[journeyId].find(
          (convertRecord) => (convertRecord.fileUrls[0].type === 'video' || convertRecord.fileUrls[0].type === 'recording'),
        )).fileUrls[0] || {};
        record.fileUrls.push(videoUrl);
        const statementsWithURLs = mapStatementImageURLS(
          statements,
          videoStatementModuleAPIRecords,
        );
        record.extractedData.details.statements = statementsWithURLs;
        return record;
      });
      recordsData['/v1/logVideoStatement'].records = finalVideoStatements;
    }

    return recordsData;
  } catch (error) {
    Sentry.captureException(`${errorCode.ERROR_PROCESS_RECORD} - ${error}`);
    return groupedRecordData;
  }
};

const filterDisabledRecords = (groupedRecordData, modules) => {
  const nonHiddenRecordsData = {};
  const nonHiddenModules = {};
  Object.keys(groupedRecordData).forEach((module) => {
    const { records } = groupedRecordData[module];
    const nonHiddenRecords = records.filter((record) => record?.hideOnPortal !== 'yes');
    if (nonHiddenRecords.length) {
      nonHiddenRecordsData[module] = cloneDeep(groupedRecordData[module]);
      nonHiddenRecordsData[module].records = nonHiddenRecords;
      nonHiddenModules[module] = modules[module];
    }
  });
  return { nonHiddenRecordsData, nonHiddenModules };
};

export const processRecordData = async (
  workflowModules,
  transactionData,
  recordData,
  getFlagConfig,
  v2Flags,
) => {
  const modules = {};
  const flagByModule = {};
  const groupedV2Flags = groupBy(v2Flags, 'source');
  const v2FlagsByModule = {};
  let groupedRecordData = {};
  const groupedHitData = {};
  const reviewTagsConfigObj = {};
  const hitsReviewTagsConfigObj = {};
  const flagConfig = await getFlagConfig();

  const { transactionId } = transactionData;

  const sortedRecordData = sortAuditData(recordData);

  sortedRecordData.forEach((item) => {
    const {
      requestId,
      endpointModule,
      moduleId: recordModuleId,
      code,
      message,
      createdAt: recordCreatedAt,
      originalUrl,
      hideOnPortal,
    } = item;

    const cardType = get(endpointModule, 'cardType', 'default');

    // Set moduleId, moduleName, subType from workflow/endpointModule
    let { moduleId } = getModuleIdNameAndSubtype({
      recordModuleId,
      originalUrl,
      workflowModules,
      cardType,
      endpointModule,
    });
    const { moduleName, moduleSubType } = getModuleIdNameAndSubtype({
      recordModuleId,
      originalUrl,
      workflowModules,
      cardType,
      endpointModule,
    });
    const expectedDocumentSide = get(endpointModule, 'conditions[0].expectedDocumentSide');

    // This status would need to be updated in future when other modules start using convert API
    if (originalUrl === '/v1/logVideoStatement' || originalUrl === '/v1/convert') moduleId = originalUrl;

    // Get flags from code and message and set latest attempt
    const translatedFlag = translateCodeAndMessageToFlag(
      code,
      message,
      originalUrl,
      flagConfig,
    );
    const flagObj = {
      moduleName,
      flag: translatedFlag,
      subType: moduleSubType,
      createdAt: recordCreatedAt,
    };

    if (has(groupedV2Flags, moduleId) && hideOnPortal !== 'yes') {
      v2FlagsByModule[moduleId] = {
        moduleName,
        moduleSubType,
        originalUrl,
        flags: get(groupedV2Flags, [moduleId]),
      };
    }

    if (expectedDocumentSide) {
      const subModule = {};
      subModule[expectedDocumentSide] = {
        flag: translatedFlag,
        createdAt: recordCreatedAt,
      };
      flagObj.subModule = subModule;
    }

    if (hideOnPortal !== 'yes') {
      if (moduleId in flagByModule) {
        if (recordCreatedAt > flagByModule[moduleId].createdAt && !expectedDocumentSide) {
          if (translatedFlag) {
            flagByModule[moduleId] = flagObj;
          } else {
            delete flagByModule[moduleId];
          }
        } else if (expectedDocumentSide && flagByModule[moduleId].subModule) {
          if (expectedDocumentSide in flagByModule[moduleId].subModule) {
            if (flagByModule[moduleId].subModule[expectedDocumentSide].createdAt
              < recordCreatedAt) {
              if (translatedFlag) {
                flagByModule[moduleId]
                  .subModule[expectedDocumentSide] = flagObj.subModule[expectedDocumentSide];
              } else {
                delete flagByModule[moduleId].subModule[expectedDocumentSide];
                if (keys(flagByModule[moduleId].subModule).length === 0) {
                  delete flagByModule[moduleId];
                }
              }
            }
          } else if (translatedFlag) {
            flagByModule[moduleId]
              .subModule[expectedDocumentSide] = flagObj.subModule[expectedDocumentSide];
          }
        }
      } else if (translatedFlag) {
        flagByModule[moduleId] = flagObj;
      }
    }

    // Get module related data for left-right panels
    const moduleObj = {
      id: moduleId,
      statusCode: item.statusCode,
      extractedData: item.extractedData,
      createdAt: item.createdAt,
      moduleName,
      subType: moduleSubType,
      // For Red circle
      isSuccess: findIsSuccess(item),
    };
    if (moduleId in modules) {
      if (recordCreatedAt > modules[moduleId].createdAt) {
        modules[moduleId] = moduleObj;
      }
    } else {
      modules[moduleId] = moduleObj;
    }

    // Group records based on moduleId, sort records in descending order
    groupedRecordData[moduleId] = {
      records: [
        {
          ...item,
          subType: moduleSubType,
          // For tick/cross in card
          isSuccess: findIsSuccess(item),
          // For Status is card
          status: findStatus(item),
        },
        ...get(groupedRecordData, [moduleId, 'records'], []),
      ],
      type: cardType,
      subType: moduleSubType,
      moduleName,
    };

    // process right panel review tags
    // Consider all attempts
    const reviewTagsConfig = get(endpointModule, 'reviewTagsConfig');
    if (!isEmpty(reviewTagsConfig)) {
      const { tagGroups } = reviewTagsConfig;
      const updatedTagGroups = tagGroups.map((tagGroup) => {
        const selectedValue = get(item, tagGroup.pathToData, null);
        return {
          ...tagGroup,
          // Add additional meta params to send in request body when tag is clicked
          metaData: {
            transactionId,
            requestId,
          },
          selectedValue,
        };
      });
      const updatedReviewTagsConfig = {
        ...reviewTagsConfig,
        tagGroups: updatedTagGroups,
        createdAt: recordCreatedAt,
      };
      set(reviewTagsConfigObj, [moduleId, requestId], updatedReviewTagsConfig);
    }

    // process hits review tags
    // Consider all hits
    const hitsConfig = get(endpointModule, 'hitsConfig');
    const hitsReviewTagConfig = get(hitsConfig, 'reviewTagsConfig');
    if (!isEmpty(hitsReviewTagConfig)) {
      const { tagGroups } = hitsReviewTagConfig;
      const hitsArray = get(item, hitsConfig.pathToHits, []);
      if (!isEmpty(hitsArray)) {
        // group hit data as well
        groupedHitData[moduleId] = [
          {
            requestId,
            hits: hitsArray,
          },
          ...get(groupedHitData, moduleId, []),
        ];

        const updatedTagGroups = tagGroups.map((tagGroup) => {
          const selectedValue = {};
          hitsArray.forEach((hitData) => {
            const value = get({ hitData }, tagGroup.pathToData, null);
            if (hitData?.hitId) selectedValue[hitData.hitId] = value;
          });
          return {
            ...tagGroup,
            // Add additional meta params to send in request body when tag is clicked
            metaData: {
              transactionId,
              requestId,
            },
            selectedValue,
          };
        });
        const updatedHitsReviewTagConfig = {
          ...hitsReviewTagConfig,
          tagGroups: updatedTagGroups,
          createdAt: recordCreatedAt,
        };

        set(hitsReviewTagsConfigObj, [moduleId, requestId], updatedHitsReviewTagConfig);
      }
    }
  });

  groupedRecordData = processRecordsForSKYC(groupedRecordData);
  const {
    nonHiddenRecordsData,
    nonHiddenModules,
  } = filterDisabledRecords(groupedRecordData, modules);

  // Order flags based on modules found above
  const orderedFlagsByModules = {};
  Object.keys(modules).forEach((moduleId) => {
    if (moduleId in flagByModule) {
      orderedFlagsByModules[moduleId] = flagByModule[moduleId];
    }
  });

  const orderedV2FlagsByModules = { flags: {} };
  Object.keys(modules).forEach((moduleId) => {
    if (has(v2FlagsByModule, moduleId)) {
      orderedV2FlagsByModules.flags[moduleId] = v2FlagsByModule[moduleId];
    }
  });

  if (Array.isArray(v2Flags)) {
    orderedV2FlagsByModules.present = true;
  }

  return {
    flagByModule: orderedFlagsByModules,
    v2FlagsByModule: orderedV2FlagsByModules,
    modules: nonHiddenModules,
    groupedRecordData: nonHiddenRecordsData,
    groupedHitData,
    reviewTagsConfigObj,
    hitsReviewTagsConfigObj,
  };
};

export const getActiveURL = (fileUrls, inactiveUrl) => {
  let activeUrl = '';
  if (!isValidURL(inactiveUrl) || !isAuditPortalURL(inactiveUrl)) return inactiveUrl;
  const { requestId, fileName } = getUrlMetadata(inactiveUrl);
  activeUrl = fileUrls.find(({ url: currentURL }) => {
    const { requestId: currentRequestId, fileName: currentFileName } = getUrlMetadata(currentURL);
    return requestId === currentRequestId && fileName === currentFileName;
  });
  return activeUrl?.url;
};

const getStatusElements = ({
  applicationStatus,
  transactionStatusHistory,
  transactionUpdatedAt,
}) => {
  // To ensure backward compatibility for the transactions which do not have statusHistory
  if (!isNonEmptyArray(transactionStatusHistory) && applicationStatus !== 'started') {
    return [{
      type: 'status',
      createdAt: transactionUpdatedAt,
      date: formatDateForTimelineHeader(transactionUpdatedAt),
      status: applicationStatus,
      time: formatDateForTimelineView(transactionUpdatedAt),
    }];
  }

  return transactionStatusHistory.reduce(
    (accumulator, {
      status, timestamp, failureReason, sdkErrorMessage,
    }) => {
      if (status === 'started') return accumulator;

      accumulator.push({
        type: 'status',
        createdAt: timestamp,
        date: formatDateForTimelineHeader(timestamp),
        status,
        time: formatDateForTimelineView(timestamp),
        failureReason: (status === 'error') ? sdkErrorMessage : failureReason,
      });

      return accumulator;
    }, [],
  );
};

export const processTimelineData = ({
  allRecords,
  workflowModules,
  transactionUpdatedAt,
  applicationStatus,
  transactionStatusHistory,
}) => {
  const attempts = {};

  const sortedRecords = sortAuditData(allRecords);

  const totalElements = sortedRecords.length;
  let currentIndex = 0;
  const formattedData = [];
  let startTransactionCount = 0;

  const statusElements = getStatusElements({
    applicationStatus,
    transactionStatusHistory,
    transactionUpdatedAt,
  });

  formattedData.push(...statusElements);

  while (currentIndex < totalElements) {
    const {
      moduleId: recordModuleId,
      originalUrl,
      createdAt,
      endpointModule,
      extractedData,
      statusCode,
      code,
      message,
      hideOnPortal,
      sdkType,
      sdkVersion,
    } = sortedRecords[currentIndex];

    const cardType = get(endpointModule, 'cardType');

    const { moduleId: moduleIdToUse, moduleSubType } = getModuleIdNameAndSubtype({
      recordModuleId,
      originalUrl,
      workflowModules,
      cardType,
      endpointModule,
    });

    if (hideOnPortal !== 'yes') {
      let attemptCount;
      if (has(attempts, moduleIdToUse)) {
        attemptCount = attempts[moduleIdToUse] + 1;
        attempts[moduleIdToUse] += 1;
      } else {
        attemptCount = 1;
        set(attempts, moduleIdToUse, 1);
      }
      formattedData.push({
        ...sortedRecords[currentIndex],
        time: formatDateForTimelineView(createdAt),
        name:
          get(workflowModules, [recordModuleId, 'name'])
          || get(endpointModule, 'moduleName')
          || startCase(last(split(originalUrl, '/'))),
        isSuccess: findIsSuccess({ extractedData, statusCode }),
        status: findStatus({ extractedData, statusCode }),
        attempt: attemptCount,
        createdAt,
        date: formatDateForTimelineHeader(createdAt),
        flag: translateCodeAndMessageToFlag(code, message, originalUrl, {}),
        extractedData,
        moduleId: moduleIdToUse,
        originalUrl,
        subType: moduleSubType,
      });
    } else if (originalUrl === URLS.START_TRANSACTION) {
      startTransactionCount += 1;

      if (startTransactionCount !== 1) {
        formattedData.push({
          type: 'resume',
          time: formatDateForTimelineView(createdAt),
          name: 'Transaction Started',
          attempt: startTransactionCount,
          createdAt,
          date: formatDateForTimelineHeader(createdAt),
          sdkType,
          sdkVersion,
        });
      }
    }
    currentIndex += 1;
  }

  return formattedData.sort((a, b) => {
    const keyA = new Date(a.createdAt);
    const keyB = new Date(b.createdAt);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
};

export const getOrdinalSuffix = (n) => {
  try {
    if (!n || !isNumber(n)) return n;

    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  } catch (error) {
    return n;
  }
};
