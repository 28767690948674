import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Details.scss';
import getIconForSubType from '../LeftPanel/utils';
import { ReactComponent as VectorIcon } from '../../../assests/icons/vector.svg';

function DetailsCollapsible({
  isOpened, children, subType, text, labelIcon,
}) {
  const [isExpanded, setIsExpanded] = useState(isOpened);
  const toggle = () => setIsExpanded((prev) => !prev);

  return (
    <>
      <div aria-hidden id="application_details_collapsible" className={`${isExpanded ? 'border_radius_default' : 'border_radius_enabled'}`} onClick={toggle}>
        <div id="application_details_collapsible__left_column">
          {React.cloneElement(getIconForSubType(subType))}
          <div id="application_details_collapsible__text">
            {text}
          </div>
          {labelIcon
          && (
            <div id="application_details_collapsible__label">
              {React.cloneElement(getIconForSubType(labelIcon))}
            </div>
          )}
        </div>
        <div
          id="application_details_collapsible__right_column"
          className={isExpanded ? 'active' : ''}
        >
          <VectorIcon />
        </div>
      </div>
      {isExpanded && <div id="application_details_collapsible__children">{children}</div>}
    </>
  );
}

DetailsCollapsible.defaultProps = {
  subType: 'application_details_icon',
  text: 'Application Details',
  isOpened: true,
  labelIcon: '',
};

DetailsCollapsible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  subType: PropTypes.string,
  text: PropTypes.string,
  isOpened: PropTypes.bool,
  labelIcon: PropTypes.string,
};

export default DetailsCollapsible;
