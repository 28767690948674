import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SummaryTab from '../Summary/SummaryTab';
import Details from './Details';
import ReasonBehindStatus from './ReasonBehindStatus';

function RecordBody({
  transactionData,
}) {
  const recordData = useSelector((state) => state.appDetails.recordData);
  const rawRecordData = useSelector((state) => state.appDetails.rawRecordData);

  return (
    <div id="record__body">
      <SummaryTab
        transactionData={transactionData}
        recordData={rawRecordData}
      />
      <ReasonBehindStatus transactionData={transactionData} />
      <Details processedRecordData={recordData} transactionData={transactionData} />
    </div>
  );
}

RecordBody.propTypes = {
  transactionData: PropTypes.object.isRequired,
};

export default RecordBody;
