import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

import '../Details.scss';
import Details from './Details';
import DetailsCollapsible from './DetailsCollapsible';

function ReasonBehindStatus({ transactionData }) {
  const { workflowId } = transactionData;
  const currentAppId = useSelector((s) => s.user.credState?.current?.appId);
  const {
    workflowModules: workflowModuleArray,
    contributingModulesForEndState,
    recordData,
  } = useSelector((state) => ({
    workflowModules: state.user.workflowModules,
    contributingModulesForEndState:
      state.appDetails.contributingModulesForEndState,
    recordData: state.appDetails.recordData,
  }));

  // const workflowModules = initWorkflowModules[`${currentAppId}_${workflowId}`];
  const workflowModules = get(
    workflowModuleArray,
    `${currentAppId}_${workflowId}.modules`,
    {},
  );

  const [processedRecordData, setProcessedRecordData] = useState([]);
  const getProcessedRecordData = () => {
    if (!contributingModulesForEndState || !workflowModules || !recordData) {
      return [];
    }

    const {
      contributingModuleIdsForEndState = [],
      contributingSuperModuleIdsForEndState = [],
    } = contributingModulesForEndState;

    // Get all module IDs that contributed to the end state
    const moduleIds = [
      ...contributingModuleIdsForEndState,
      ...contributingSuperModuleIdsForEndState,
    ].reduce((uniqueSet, moduleId) => {
      workflowModules.forEach((module) => {
        if (module.id === moduleId || module.superModuleId === moduleId) {
          uniqueSet.add(module.id); // Add the module id to the set
        }
      });
      return uniqueSet;
    }, new Set());

    // Convert the Set back to an array
    const finalModuleIds = [...Array.from(moduleIds)];
    // Process the records
    const finalRecords = finalModuleIds.reduce((acc, moduleId) => {
      const record = recordData[moduleId];
      if (!record) return acc;

      // Create a new record object with only the first record (since latest is required)
      const processedRecord = {
        ...record,
        records:
          record.records?.length > 1 ? [record.records[0]] : record.records,
      };

      return [...acc, processedRecord];
    }, []);
    setProcessedRecordData(finalRecords);
    return finalRecords;
  };

  useEffect(() => {
    getProcessedRecordData();
  }, []);

  return (
    <div id="reason_behind_status_container">
      {processedRecordData && !isEmpty(processedRecordData) && (
        <DetailsCollapsible
          subType="reason_behind_status_icon"
          text="Reason Behind the Status"
          labelIcon="new_badge_icon"
        >
          <Details
            processedRecordData={processedRecordData}
            transactionData={transactionData}
          />
        </DetailsCollapsible>
      )}
    </div>
  );
}

ReasonBehindStatus.propTypes = {
  transactionData: PropTypes.object.isRequired,
};

export default ReasonBehindStatus;
