import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { APPLICATION_DETAILS_STATUS_TOOLTIP_MAP } from '../../../../constants/applicationStatus';
import ConditionalToolTip from '../../../Common/ConditionalToolTip';
import StatusTag from '../../../Common/Tags/StatusTag';

function Header({ status }) {
  const tooltip = get(APPLICATION_DETAILS_STATUS_TOOLTIP_MAP, status, '');
  return (
    <div id="right_panel__review_card__status">
      <p>Status:</p>
      <ConditionalToolTip title={tooltip} placement="left">
        <StatusTag type="status" status={status} />
      </ConditionalToolTip>
    </div>
  );
}

Header.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Header;
